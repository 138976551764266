import React, { useState, useContext, ReactNode, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useNavigationItemHandle from '../../hooks/useNavigationItemHandle';
import AuthContext from '../../contexts/authContext';
import LangCommon from '../../components/langcommon/LangCommon';

const LanguageSelector = () => {
	const { userData, setAuthToken } = useContext(AuthContext);
	const navigate = useNavigate();
	const handleItem = useNavigationItemHandle();
	const [collapseStatus, setCollapseStatus] = useState<boolean>(false);
	const [showPopup, setShowPopup] = useState<boolean>(false);
	const handlePopup = () => {		
		setShowPopup(!showPopup);
	};
	const { t } = useTranslation(['translation', 'menu']);		

	return (		
		<>			
			<nav aria-label="aside-modules">
				<ul id="aside-modules" className="navigation">
					<li className="navigation-item" onClick={handlePopup}>
						<span className="navigation-link navigation-link-pill cursor-pointer">
							<span className="navigation-link-info">
								<svg viewBox="0 0 24 24" fill="currentColor" className="svg-icon--material svg-icon navigation-icon" data-name="Material--Language"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M5.08 8h2.95c.32-1.25.78-2.45 1.38-3.56-1.84.63-3.37 1.9-4.33 3.56zm2.42 4c0-.68.06-1.34.14-2H4.26c-.16.64-.26 1.31-.26 2s.1 1.36.26 2h3.38c-.08-.66-.14-1.32-.14-2zm-2.42 4a7.987 7.987 0 004.33 3.56A15.65 15.65 0 018.03 16H5.08zM12 4.04c-.83 1.2-1.48 2.53-1.91 3.96h3.82c-.43-1.43-1.08-2.76-1.91-3.96zM18.92 8a8.03 8.03 0 00-4.33-3.56c.6 1.11 1.06 2.31 1.38 3.56h2.95zM12 19.96c.83-1.2 1.48-2.53 1.91-3.96h-3.82c.43 1.43 1.08 2.76 1.91 3.96zm2.59-.4A8.03 8.03 0 0018.92 16h-2.95a15.65 15.65 0 01-1.38 3.56zM19.74 10h-3.38c.08.66.14 1.32.14 2s-.06 1.34-.14 2h3.38c.16-.64.26-1.31.26-2s-.1-1.36-.26-2zM9.66 10c-.09.65-.16 1.32-.16 2s.07 1.34.16 2h4.68c.09-.66.16-1.32.16-2s-.07-1.35-.16-2H9.66z" opacity="0.3"></path><path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zm6.93 6h-2.95a15.65 15.65 0 00-1.38-3.56A8.03 8.03 0 0118.92 8zM12 4.04c.83 1.2 1.48 2.53 1.91 3.96h-3.82c.43-1.43 1.08-2.76 1.91-3.96zM4.26 14C4.1 13.36 4 12.69 4 12s.1-1.36.26-2h3.38c-.08.66-.14 1.32-.14 2s.06 1.34.14 2H4.26zm.82 2h2.95c.32 1.25.78 2.45 1.38 3.56A7.987 7.987 0 015.08 16zm2.95-8H5.08a7.987 7.987 0 014.33-3.56A15.65 15.65 0 008.03 8zM12 19.96c-.83-1.2-1.48-2.53-1.91-3.96h3.82c-.43 1.43-1.08 2.76-1.91 3.96zM14.34 14H9.66c-.09-.66-.16-1.32-.16-2s.07-1.35.16-2h4.68c.09.65.16 1.32.16 2s-.07 1.34-.16 2zm.25 5.56c.6-1.11 1.06-2.31 1.38-3.56h2.95a8.03 8.03 0 01-4.33 3.56zM16.36 14c.08-.66.14-1.32.14-2s-.06-1.34-.14-2h3.38c.16.64.26 1.31.26 2s-.1 1.36-.26 2h-3.38z"></path></svg>
								<span className="navigation-text">{t("Change Language") as ReactNode}</span>
							</span>
						</span>
					</li>					
				</ul>
			</nav>
			{showPopup && <LangCommon setShowPopup={setShowPopup} showPopup={showPopup}></LangCommon>}			
		</>
	);
};
export default LanguageSelector;
