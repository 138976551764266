import React, { useCallback, useContext, useEffect, useLayoutEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import { useFormik } from 'formik';
import Spinner from '../../../components/bootstrap/Spinner';
import { authenticationService} from '../../../services';
import AuthContext from '../../../contexts/authContext';
import showNotification from '../../../components/extras/showNotification';
import logo from '../../../assets/img/bitte_logo.png';
import { useTranslation } from 'react-i18next';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../../../components/bootstrap/Dropdown';
import LANG, { getLangWithKey, ILang } from '../../../lang';

const Login = () => {
	const CryptoJS = require("crypto-js");
	const navigate = useNavigate();
	const { authToken, setAuthToken, userData } = useContext(AuthContext);
	const [switchForm, setSwitchForm] = useState<string>('Login');	
	const { t } = useTranslation('auth');	
	const {i18n} = useTranslation();		
	const changeLanguage = (lng: ILang['key']['lng']) => {
		i18n.changeLanguage(lng).then(() =>{
			/*showNotification(
				<span className='d-flex align-items-center'>
					<Icon icon={getLangWithKey(lng)?.icon} size='lg' className='me-1' />
					<span>{`Language changed to ${getLangWithKey(lng)?.text}`}</span>
				</span>,
				'You updated the language of the site.',
			)*/
			 // For the registration form
			reValidateForm();
		});
	};

	const reValidateForm = () => {
		if (switchForm === 'Login') {
			formik.validateForm();
		} else if (switchForm === 'Register') {
			registrationForm.validateForm();
		} else if (switchForm === 'Forgot') {
			forgotPasswordForm.validateForm();
		}
	}
	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			username: '',
			password: '',
		},
		validate: (values) => {
			const errors: { username?: string; password?: string } = {};

			if (!values.username) {
				errors.username = t('usernameRequired') || '';//'Username is a required field';
			}

			if (!values.password) {
				errors.password = t('passwordRequired') || '';//'Password is a required field';
			}

			return errors;
		},
		validateOnChange: false,
		onSubmit: (values) => {
			//console.log(values)
			loginUser(values);			
		},
	});

	const registrationForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			firstname:'',
			lastname:'',
			mobile:'',
			email: '',
			password: '',
			confirmPassword: '',
		},
		validate: (values) => {
			const errors: { firstname?: string; lastname?: string; mobile?: string; email?: string; password?: string; confirmPassword?: string } = {};

			if (!values.firstname) {
				errors.firstname = t('firstNameRequired') || ''; //'Firstname is a required field';
			}
			if (!values.lastname) {
				errors.lastname = t('lastNameRequired') || ''; //'Lastname is a required field';
			}
			if (!values.mobile) {
				errors.mobile = t('mobileRequired') || ''; //'Mobile number is a required field';
			}else if (!/^[0-9+()]+$/.test(values.mobile)) {
				errors.mobile = t('invalidMobile') || ''; //'Invalid Mobile number';
			}

			if (!values.email) {
				errors.email = t('emailRequired') || '';//'Email address is a required field';
			}else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)){
				errors.email = t('invalidEmail') || '';//'Invalid email address';
			}
			if (!values.password) {
				errors.password = t('passwordRequired') || '';//'Password is a required field';
			}
			if (!values.confirmPassword) {
				errors.confirmPassword = t('confirmPasswordRequired') || '';//'Confirm password is a required field';
			} else if (values.password !== values.confirmPassword) {
				errors.confirmPassword = t('passwordNotMatch') || '';//'Passwords do not match';
			}

			return errors;
		},

		validateOnChange: false,
		onSubmit: (values) => {
			const data = {
				"firstname": values.firstname,
				"lastname": values.lastname,
				"mobile": values.mobile,
				"email": values.email,
				"password": CryptoJS.AES.encrypt(values.password, '4Fox@321').toString()		
			}
			registerUser(data);
		},
	});

	const forgotPasswordForm = useFormik({
		enableReinitialize: true,
		initialValues: {
		  email: '',
		},
		validate: (values:any) => {
			const errors: { email?: string } = {};		  
			if (!values.email) {
				errors.email = t('emailRequired') || '';//'Email address is a required field';
			} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)){
				errors.email = t('invalidEmail') || '';//'Invalid email address';
			}
		  	return errors;
		},
		onSubmit: (values, { resetForm }) => {
		  authenticationService.forgotPassword(values, i18n.language)
			.then((data) => {
			  showNotification('Success', data.results.message, 'success');
			  resetForm();
			})
			.catch((error) => {
			  showNotification('Error', error, 'danger');
			})
		},
	});
	
	const loginUser = (data: any) => {
        authenticationService.login(data, i18n.language)
        .then((accessToken) => {
			//console.log(accessToken)
			if (setAuthToken) {
				setAuthToken(accessToken);
			}  			
        })
        .catch((error)=>{
			//console.log(error)
			showNotification('Error',error,'danger');
		});
    }

	const registerUser = (data: any) => {
        authenticationService.signup(data, i18n.language)
        .then((data) => {
			showNotification('Success',data.results.message,'success');	
			registrationForm.resetForm();
			setSwitchForm('Login');
			//console.log(data)			
        })
        .catch((error)=>{
			//console.log(error)
			showNotification('Error',error,'danger');
		});
    }

	useEffect(() => {
		//console.log('userData123=>>',userData)
		window.setTimeout(()=>{
			if(authToken!=''){
				navigate('/landing')			
			}else{
				navigate('/')				
			}	
		}, 100);	
		//	eslint-disable-next-line react-hooks/exhaustive-deps		
    },[authToken])

	/**
	 * Language attribute
	 */
	useLayoutEffect(() => {
		document.documentElement.setAttribute('lang', i18n.language);
	});

	return (
		<PageWrapper
			isProtected={false}
			title={'Login'}
			className={classNames({ 'bg-dark': false, 'bg-light': true })}>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-5'>
									<Link
										to='/'
										className='text-decoration-none  fw-bold display-2'>										
										<img src={logo} width={200}/>
									</Link>									
								</div>	
								<div className='text-center'>
									<Dropdown>
										<DropdownToggle>
											{typeof getLangWithKey(i18n.language as ILang['key']['lng'])
												?.icon === 'undefined' ? (
												<Button
													className='btn-only-icon'
													aria-label='Change language'
													data-tour='lang-selector'>
													<Spinner isSmall inButton='onlyIcon' isGrow />
												</Button>
											) : (
												<Button
													icon={
														getLangWithKey(i18n.language as ILang['key']['lng'])
															?.icon
													}
													aria-label='Change language'
													data-tour='lang-selector'
												/>												
											)}
										</DropdownToggle>
										<DropdownMenu isAlignmentEnd data-tour='lang-selector-menu'>
											{Object.keys(LANG).map((i) => (
												<DropdownItem key={LANG[i].lng}>
													<Button
														icon={LANG[i].icon}
														onClick={() => changeLanguage(LANG[i].lng)}>
														{LANG[i].text}
													</Button>
												</DropdownItem>
											))}
										</DropdownMenu>
									</Dropdown>
								</div>
								{switchForm=='Login'?
								<>
									<form className='row g-4'>
										<div className='col-12'>
											<FormGroup
												id='username'
												label={t('emailAddress') as string | undefined}>
												<Input
													autoComplete='username'
													value={formik.values.username}
													isTouched={formik.touched.username}
													invalidFeedback={
														formik.errors.username
													}
													isValid={formik.isValid}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													onFocus={() => {
														formik.setErrors({});
													}}
												/>
											</FormGroup>
										</div>
										<div className='col-12'>	
											<FormGroup id='password' label={t('password') as string | undefined}>
												<Input
													type='password'
													autoComplete='current-password'
													value={formik.values.password}
													isTouched={formik.touched.password}
													invalidFeedback={
														formik.errors.password
													}
													isValid={formik.isValid}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
												/>
											</FormGroup>
										</div>
										<div className='my-1'>
											<Button type='button'
												color='link'														
												onClick={()=>setSwitchForm('Forgot')} size='lg'>									
												{t('forgotPassword')}
											</Button>										
										</div>
										<div className='col-12'>										
											<Button type='submit'
												color='dark'
												isOutline={true}
												rounded={0}
												className='w-100 py-3 ap-btn'
												onClick={formik.handleSubmit}>
												{t('login')}
											</Button>
										</div>									
									</form>
									<div className='text-center my-3'>
										{t('newToBitte')}<Button type='button'
																color='link'														
																onClick={()=>setSwitchForm('Register')} size='lg'>									
																{t('joinNow')}
															</Button>
									</div>		
								</>:(switchForm=='Forgot'?
								<>
									<form className='row g-4'>
										<div className='col-12'>
											<FormGroup
											id='email'
											label={t('emailAddress') as string | undefined}>
											<Input
												autoComplete='email'
												value={forgotPasswordForm.values.email}
												isTouched={forgotPasswordForm.touched.email}
												invalidFeedback={forgotPasswordForm.errors.email}
												isValid={forgotPasswordForm.isValid}
												onChange={forgotPasswordForm.handleChange}
												onBlur={forgotPasswordForm.handleBlur}
												onFocus={() => {
												formik.setErrors({});
												}}
											/>
											</FormGroup>
										</div>
										<div className='col-12'>
											<Button
											type='submit'
											color='dark'
											isOutline={true}
											rounded={0}
											className='w-100 py-3 ap-btn'
											onClick={forgotPasswordForm.handleSubmit}
											>
											{t('resetPassword')}
											</Button>
										</div>
										<div className='text-center my-3'>
											<Button type='button'
												color='link'														
												onClick={()=>setSwitchForm('Login')}
												size='lg'>									
												{t('back')}
											</Button>
										</div>	
									</form>
								</>
								:		
								<>
									<form className='row g-4'>
										<div className='col-6'>
											<FormGroup
												id='firstname'
												label={t('firstname') as string | undefined}>
												<Input
													value={registrationForm.values.firstname}
													isTouched={registrationForm.touched.firstname}
													invalidFeedback={
														registrationForm.errors.firstname
													}
													isValid={registrationForm.isValid}
													onChange={registrationForm.handleChange}
													onBlur={registrationForm.handleBlur}
													onFocus={() => {
														registrationForm.setErrors({});
													}}
												/>
											</FormGroup>
										</div>
										<div className='col-6'>
											<FormGroup
												id='lastname'
												label={t('lastname') as string | undefined}>
												<Input
													value={registrationForm.values.lastname}
													isTouched={registrationForm.touched.lastname}
													invalidFeedback={
														registrationForm.errors.lastname
													}
													isValid={registrationForm.isValid}
													onChange={registrationForm.handleChange}
													onBlur={registrationForm.handleBlur}
													onFocus={() => {
														registrationForm.setErrors({});
													}}
												/>
											</FormGroup>
										</div>
										<div className='col-12'>
											<FormGroup
												id='mobile'
												label={t('mobile') as string | undefined}>
												<Input
													value={registrationForm.values.mobile}
													isTouched={registrationForm.touched.mobile}
													invalidFeedback={
														registrationForm.errors.mobile
													}
													isValid={registrationForm.isValid}
													onChange={registrationForm.handleChange}
													onBlur={registrationForm.handleBlur}
													onFocus={() => {
														registrationForm.setErrors({});
													}}
												/>
											</FormGroup>
										</div>
										<div className='col-12'>
											<FormGroup
												id='email'
												label={t('emailAddress') as string | undefined}>
												<Input
													value={registrationForm.values.email}
													isTouched={registrationForm.touched.email}
													invalidFeedback={
														registrationForm.errors.email
													}
													isValid={registrationForm.isValid}
													onChange={registrationForm.handleChange}
													onBlur={registrationForm.handleBlur}
													onFocus={() => {
														registrationForm.setErrors({});
													}}
												/>
											</FormGroup>
										</div>
										<div className='col-6'>	
											<FormGroup id='password' label={t('password') as string | undefined}>
												<Input
													type='password'
													value={registrationForm.values.password}
													isTouched={registrationForm.touched.password}
													invalidFeedback={
														registrationForm.errors.password
													}
													isValid={registrationForm.isValid}
													onChange={registrationForm.handleChange}
													onBlur={registrationForm.handleBlur}
												/>
											</FormGroup>
										</div>
										<div className='col-6'>	
											<FormGroup id='confirmPassword' label={t('confirmPassword') as string | undefined}>
												<Input
													type='password'
													value={registrationForm.values.confirmPassword}
													isTouched={registrationForm.touched.confirmPassword}
													invalidFeedback={
														registrationForm.errors.confirmPassword
													}
													isValid={registrationForm.isValid}
													onChange={registrationForm.handleChange}
													onBlur={registrationForm.handleBlur}
												/>
											</FormGroup>
										</div>
										<div className='text-center my-3'>
											{t('agreementText')}
										</div>
										<div className='col-12'>										
											<Button type='submit'
												color='dark'
												isOutline={true}
												rounded={0}
												className='w-100 py-3 ap-btn'
												onClick={registrationForm.handleSubmit}>
												{t('agreeAndJoin')}
											</Button>
										</div>									
									</form>
									<div className='text-center my-3'>
									{t('alreadyRegistered')}<Button type='button'
																color='link'														
																onClick={()=>setSwitchForm('Login')} size='lg'>									
																{t('signIn')}
															</Button>										
									</div>		
								</>)}						
							</CardBody>
						</Card>						
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};
export default Login;
