import React, { ReactNode, useContext, useLayoutEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Brand from '../../../layout/Brand/Brand';
import Navigation, { NavigationLine } from '../../../layout/Navigation/Navigation';
import User from '../../../layout/User/User';
import LanguageSelector from '../../../layout/LanguageSelector/LanguageSelector';
import { dashboardPagesMenu, supportMenu } from '../../../menu';
import ThemeContext from '../../../contexts/themeContext';
import Icon from '../../../components/icon/Icon';
import Aside, { AsideBody, AsideFoot, AsideHead } from '../../../layout/Aside/Aside';
import Popovers from '../../../components/bootstrap/Popovers';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../../../components/bootstrap/Dropdown';
import LANG, { getLangWithKey, ILang } from '../../../lang';
import showNotification from '../../../components/extras/showNotification';
import Button from '../../../components/bootstrap/Button';
import Spinner from '../../../components/bootstrap/Spinner';
const DefaultAside = () => {
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);
	
	return (
		<Aside>
			<AsideHead>
				<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
			</AsideHead>
			<AsideBody>
				<Navigation menu={dashboardPagesMenu} id='aside-dashboard' />				
				{/*<NavigationLine />*/}
			</AsideBody>
			<AsideFoot>			
				<Navigation menu={supportMenu} id='aside-modules' />	
				<LanguageSelector />
				<User />
			</AsideFoot>
		</Aside>
	);
};

export default DefaultAside;
