import { fetchWrapper } from '../helpers';

export const itemsServices = {
    getAllRestItems,
    updateItemOrder,
    getItems,
    deleteItem,
    getAllIngredient,
    getAllAddons,
    getAllRestrictions,
    createIngredient,
    createRestrication,
    toggleItem,
    getAllItemsByRestId,
    deleteItemImage,
    fetchAllRestItems
};

function getAllRestItems(restaurantId: number) {
    return fetchWrapper.get(`${process.env.REACT_APP_API_URL}/rests/cat/${restaurantId}`);
}

function fetchAllRestItems(restaurantId: number) {
    return fetchWrapper.get(`${process.env.REACT_APP_API_URL}/rests/fetch/${restaurantId}`);
}

function updateItemOrder(params: any) {
    return fetchWrapper.put(`${process.env.REACT_APP_API_URL}/item/order`,params);
}

function getItems(subCatId: number) {
    return fetchWrapper.get(`${process.env.REACT_APP_API_URL}/rests/item/${subCatId}`);
}

function deleteItem(itemId: number) {
    return fetchWrapper.delete(`${process.env.REACT_APP_API_URL}/rests/item/${itemId}`);
}

function getAllIngredient(restaurantId:number,srchString:string) {
    return fetchWrapper.get(`${process.env.REACT_APP_API_URL}/ingredient/${restaurantId}/${srchString}`);
}

function getAllAddons(restaurantId:number,srchString:string) {
    return fetchWrapper.get(`${process.env.REACT_APP_API_URL}/rests/srchItems/${restaurantId}/${srchString}`);
}

function getAllRestrictions(restaurantId:number,srchString:string) {
    return fetchWrapper.get(`${process.env.REACT_APP_API_URL}/diet/${restaurantId}/${srchString}`);
}

function createIngredient(params: any) {
    return fetchWrapper.post(`${process.env.REACT_APP_API_URL}/ingredient`, params);
}

function createRestrication(params: any) {
    return fetchWrapper.post(`${process.env.REACT_APP_API_URL}/diet`, params);
}

function toggleItem(itemId: number, value:boolean) {
    return fetchWrapper.put(`${process.env.REACT_APP_API_URL}/rests/toggle/item/${itemId}/${value}`);
}

function getAllItemsByRestId(restaurantId: number) {
    return fetchWrapper.get(`${process.env.REACT_APP_API_URL}/item/list/${restaurantId}`);
}

function deleteItemImage(itemId: number, fileName:string) {
    return fetchWrapper.delete(`${process.env.REACT_APP_API_URL}/item/img/${itemId}/${fileName}`);
}


