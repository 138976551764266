export const dashboardPagesMenu = {
	/*dashboard: {
		id: 'admin-dashboard',
		text: 'Dashboard',
		path: '/admin-dashboard',
		icon: 'Dashboard',
		subMenu: null,
	},*/
	restaurants: {
		id: 'restaurants',
		text: 'Restaurants',
		path: 'restaurants',
		icon: 'Restaurant',
		subMenu: null,
	}	
};

export const restaurantPagesMenu = {
	/*dashboard: {
		id: 'restaurant-dashboard',
		text: 'Dashboard',
		path: '/restaurant-dashboard',
		icon: 'Dashboard',
		subMenu: null,
	},*/
	restaurants: {
		id: 'restaurants',
		text: 'Restaurants',
		path: 'restaurants',
		icon: 'Restaurant',
		subMenu: null,
	}
};

export const userProfilePagesMenu = {	
	profile: {
		id: 'profile',
		text: 'Profile',
		path: 'profile',
		icon: 'ManageAccounts',
		subMenu: null,
	}
};

export const restaurantDetailsPagesMenu = {
	restaurantDetails: {
		id: 'restaurant-details',
		text: 'Restaurant Details',
		path: 'restaurant-details',
		icon: 'Restaurant',
		subMenu: null,
	},		
	menu: {
		id: 'menu',
		text: 'Menus',
		path: 'menu',
		icon: 'RestaurantMenu',		
		subMenu: {
			items: {
				id: 'edit-menu',
				text: 'Edit menu',
				path: 'editmenu',
				icon: 'RestaurantMenu',
			},	
			print: {
				id: 'print-menu',
				text: 'Print menu',
				path: 'printmenu',
				icon: 'Print',
			},	
			categories: {
				id: 'categories',
				text: 'Categories',
				path: 'categories',
				icon: 'Category',
			},
			subCategories: {
				id: 'subcategories',
				text: 'Sub-categories',
				path: 'subcat',
				icon: 'Category',
			},
			itemsView: {
				id: 'itemsview',
				text: 'Items',
				path: 'itemsview',
				icon: 'FormatListBulleted',
			},					
			themeTable: {
				id: 'theme-table',
				text: 'Theme selection',
				path: 'themeselection',
				icon: 'FileCopy',
			},
			translationCenter: {
				id: 'translation-center',
				text: 'Translation Center',
				path: 'translationcenter',
				icon: 'Language',
			},
		},
	}
};

export const supportMenu = {
	contact: {
		id: 'contact',
		text: 'Contact us',
		path: 'contact',
		icon: 'ContactMail',
	},
	faq: {
		id: 'faq',
		text: 'FAQ',
		path: 'faq',
		icon: 'QuestionAnswer',
	},
};

export const authPagesMenu = {
	auth: {
		id: 'auth',
		text: 'Auth Pages',
		icon: 'Extension',
	},
	login: {
		id: 'login',
		text: 'Login',
		path: '/',
		icon: 'Login',
	},
	page404: {
		id: 'Page404',
		text: '404 Page',
		path: 'auth-pages/404',
		icon: 'ReportGmailerrorred',
	},
};

