import React, { ReactNode, useEffect, useLayoutEffect, useState } from 'react';
import Button from '../bootstrap/Button';
import { authenticationService, restaurantsServices} from '../../services';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../bootstrap/Modal';
import showNotification from '../extras/showNotification';
import { useTranslation } from 'react-i18next';
import LANG, { getLangWithKey, ILang } from '../../lang';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../bootstrap/Dropdown';
import Spinner from '../bootstrap/Spinner';
const LangCommon=(props:any)=> {
    const restaurantId = props.restaurantId;
    const { t } = useTranslation('translation');    
    const {i18n} = useTranslation();	

	const changeLanguage = (lng: ILang['key']['lng']) => {
        console.log('lng=>',lng)
		i18n.changeLanguage(lng).then(() =>{
			/*showNotification(
				<span className='d-flex align-items-center'>
					<Icon icon={getLangWithKey(lng)?.icon} size='lg' className='me-1' />
					<span>{`Language changed to ${getLangWithKey(lng)?.text}`}</span>
				</span>,
				'You updated the language of the site.',
			),*/			
			authenticationService.updateLang(lng)
			.then((data:any) => {
				showNotification('Success', data.results.message, 'success');
			})
			.catch((error:any)=>{
				showNotification('Error',error,'danger');
			});
		});
	};

	/**
	 * Language attribute
	 */
	useLayoutEffect(() => {
		document.documentElement.setAttribute('lang', i18n.language);
	});
    

    useEffect(() => {        
		//	eslint-disable-next-line react-hooks/exhaustive-deps		
    },[])


    return (
        <Modal
            setIsOpen={props.setShowPopup}
            isOpen={props.showPopup}
            titleId='upcomingEdit'
            isCentered
            isStaticBackdrop={true}
            size={ "sm"}>						
            <ModalHeader setIsOpen={props.setShowPopup}>
                <ModalTitle id='exampleModalLabel'>{t('Language')}</ModalTitle>
            </ModalHeader>					
                <ModalBody >		
                    <div className='text-center'>
                        <Dropdown className='ap-lang-drop'>
                            <DropdownToggle>
                                {typeof getLangWithKey(i18n.language as ILang['key']['lng'])
                                    ?.icon === 'undefined' ? (
                                    <Button
                                        className='btn-only-icon'
                                        aria-label='Change language'
                                        data-tour='lang-selector'>
                                        <Spinner isSmall inButton='onlyIcon' isGrow />
                                    </Button>
                                ) : (
                                    <Button
                                        icon={
                                            getLangWithKey(i18n.language as ILang['key']['lng'])
                                                ?.icon
                                        }
                                        aria-label='Change language'
                                        data-tour='lang-selector'
                                    >{t("Change Language") as ReactNode}
                                    </Button>
                                )}
                            </DropdownToggle>
                            <DropdownMenu isAlignmentEnd data-tour='lang-selector-menu'>
                                {Object.keys(LANG).map((i) => (
                                    <DropdownItem key={LANG[i].lng}>
                                        <Button
                                            icon={LANG[i].icon}
                                            onClick={() => changeLanguage(LANG[i].lng)}>
                                            {LANG[i].text}
                                        </Button>
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>		
                    </div>    
                </ModalBody>
                <ModalFooter className='bg-transparent'>
                    {/*<Button className='ap-btn' type='button'
                        color='dark'
                        isOutline={true}
                        rounded={0}						
                        //onClick={handleDownload}
                        >									
                        {t('Save')}
                                </Button>*/}
                    <Button className='ap-btn' type='button'
                        color='dark'
                        isOutline={true}
                        rounded={0}						
                        onClick={()=>props.setShowPopup(false)}>									
                        {t('Close')}
                    </Button>
                </ModalFooter>
        </Modal>
    );
}
export default LangCommon;