import { fetchWrapper } from '../helpers';

export const restaurantsServices = {
    registerRestaurant,
    updateRestaurant,
    getRestaurants,
    getRestaurantDetails,
    generateQrCode,
    deleteRestaurant,
    themeSelection,
    toggleRest,
    contactUs,
    socialLinks,
    updateLangItemName,
    updateLangItemDesc,
    updateLang,
    getLangs,
    getUsersIngredients,
    getUsersRestrictions,
    fetchRestaurantDetails,
    duplicateRest,
    restaurantBgDelete,
    importItems
};

function registerRestaurant(params: any) {
    return fetchWrapper.post(`${process.env.REACT_APP_API_URL}/rests`, params);
}

function updateRestaurant(restaurantId: number, params: any) {
    return fetchWrapper.put(`${process.env.REACT_APP_API_URL}/rests/${restaurantId}`,params);
}

function getRestaurants() {
    return fetchWrapper.get(`${process.env.REACT_APP_API_URL}/rests`);
}

function getRestaurantDetails(restaurantId: number) {
    return fetchWrapper.get(`${process.env.REACT_APP_API_URL}/rests/${restaurantId}`);
}

function generateQrCode(restaurantId: number) {
    return fetchWrapper.get(`${process.env.REACT_APP_API_URL}/rests/qr/${restaurantId}`);
}

function deleteRestaurant(restaurantId: number) {
    return fetchWrapper.delete(`${process.env.REACT_APP_API_URL}/rests/${restaurantId}`);
}

function fetchRestaurantDetails(restaurantId: number) {
    return fetchWrapper.get(`${process.env.REACT_APP_API_URL}/rests/logo/${restaurantId}`);
}


function themeSelection(restaurantId: number, themeId: number) {
    return fetchWrapper.put(`${process.env.REACT_APP_API_URL}/rests/attach/theme/${restaurantId}/${themeId}`);
}

function toggleRest(restaurantId: number, value:boolean) {
    return fetchWrapper.put(`${process.env.REACT_APP_API_URL}/rests/toggle/restaurant/${restaurantId}/${value}`);
}

function contactUs(params: any, lang:any) {
    return fetchWrapper.post(`${process.env.REACT_APP_API_URL}/contactus/${lang}`, params);
}

function socialLinks(restaurantId: number, params: any) {
    return fetchWrapper.put(`${process.env.REACT_APP_API_URL}/rests/social/${restaurantId}`,params);
}

function updateLangItemName(type: string, id: number, lang: string, params:any) {
    return fetchWrapper.put(`${process.env.REACT_APP_API_URL}/rests/language/${type}/${id}/${lang}`, params);
}

function updateLangItemDesc(itemId: number, lang: string, params:any) {
    return fetchWrapper.put(`${process.env.REACT_APP_API_URL}/item/language/${itemId}/${lang}`, params);
}

function updateLang(restaurantId: number, params: any) {
    return fetchWrapper.put(`${process.env.REACT_APP_API_URL}/rests/update/lang/${restaurantId}`,params);
}

function getLangs(restaurantId: number) {
    return fetchWrapper.get(`${process.env.REACT_APP_API_URL}/rests/lang/${restaurantId}`);
}

function getUsersIngredients(restaurantId: number) {
    return fetchWrapper.get(`${process.env.REACT_APP_API_URL}/ingredient/new/${restaurantId}`);
}

function getUsersRestrictions(restaurantId: number) {
    return fetchWrapper.get(`${process.env.REACT_APP_API_URL}/diet/new/${restaurantId}`);
}

function duplicateRest(restaurantId: number) {
    return fetchWrapper.put(`${process.env.REACT_APP_API_URL}/rests/duplicate/${restaurantId}`);
}

function restaurantBgDelete(restaurantId: number) {    
    return fetchWrapper.delete(`${process.env.REACT_APP_API_URL}/rests/bgImg/${restaurantId}`);
}

function importItems(restaurantId: number, params: any) {
    return fetchWrapper.put(`${process.env.REACT_APP_API_URL}/rests/import/${restaurantId}`,params);
}
