import React from 'react';
import { RouteProps } from 'react-router-dom';
import { dashboardPagesMenu, authPagesMenu } from '../menu';
import DashboardHeader from '../pages/_layout/_headers/DashboardHeader';
import DefaultHeader from '../pages/_layout/_headers/DefaultHeader';

const headers: RouteProps[] = [
	{ path: authPagesMenu.login.path, element: null },
	{ path: authPagesMenu.page404.path, element: null },
	/*{ path: dashboardPagesMenu.dashboard.path, element: <DashboardHeader /> },
	{ path: dashboardPagesMenu.dashboard.path, element: null },*/
	{
		path: `*`,
		//element: <DefaultHeader />,
		element: null,
	},
];

export default headers;
