import React from 'react';
import { RouteProps } from 'react-router-dom';
import { authPagesMenu, restaurantDetailsPagesMenu, restaurantPagesMenu, supportMenu } from '../menu';
import DefaultAside from '../pages/_layout/_asides/DefaultAside';
import RestaurantsAside from '../pages/_layout/_asides/RestaurantsAside';

const asides: RouteProps[] = [
	{ path: authPagesMenu.login.path, element: null },	
	{ path: '*', element: <DefaultAside /> },


	//IsAdmin==false
	//{ path: restaurantPagesMenu.dashboard.path, element: <RestaurantsAside /> },
	{ path: restaurantPagesMenu.restaurants.path, element: <DefaultAside /> },
	{ path: restaurantDetailsPagesMenu.menu.subMenu.categories.path, element: <RestaurantsAside /> },
	{ path: restaurantDetailsPagesMenu.menu.subMenu.subCategories.path, element: <RestaurantsAside /> },

	{ path: restaurantDetailsPagesMenu.menu.subMenu.items.path, element: <RestaurantsAside /> },
	{ path: restaurantDetailsPagesMenu.menu.subMenu.print.path, element: <RestaurantsAside /> },
	{ path: restaurantDetailsPagesMenu.menu.subMenu.itemsView.path, element: <RestaurantsAside /> },

	{ path: restaurantDetailsPagesMenu.menu.subMenu.themeTable.path, element: <RestaurantsAside /> },
	{ path: restaurantDetailsPagesMenu.menu.subMenu.translationCenter.path, element: <RestaurantsAside /> },
	
];

export default asides;
