import { authHeader } from '.';

export const fetchWrapper = {
    get,
    post,
    postFile,
    put,
    delete:deleteFn,
    handleResponse,
};

async function get(url) {
    let headers = authHeader();
    headers['Content-Type'] = 'application/json';

    const requestOptions = {
        method: 'GET',
        headers: headers 
    };
    const response = await fetch(url, requestOptions);
    return handleResponse(response);
}

async function post(url, body) {

    let headers = authHeader();
    headers['Content-Type'] = 'application/json';

    const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(body)
    };
    const response = await fetch(url, requestOptions);
    return handleResponse(response);
}

async function postFile(url, body) {
    let headers = authHeader();
    const requestOptions = {
        method: 'POST',
        headers: headers,
        body: body
    };
    const response = await fetch(url, requestOptions);
    return handleResponse(response);
}

async function put(url, body) {

    let headers = authHeader();
    headers['Content-Type'] = 'application/json';

    const requestOptions = {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify(body)
    };
    const response = await fetch(url, requestOptions);
    return handleResponse(response);    
}

// prefixed with underscored because delete is a reserved word in javascript
async function deleteFn(url) {

    let headers = authHeader();
    headers['Content-Type'] = 'application/json';

    const requestOptions = {
        method: 'DELETE',
        headers: headers,
    };
    const response = await fetch(url, requestOptions);
    return handleResponse(response);
}

// helper functions

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}