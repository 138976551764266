import { fetchWrapper } from '../helpers';

export const authenticationService = {
    login,
    signup,
    forgotPassword,
    changePassword,
    updateProfile,
    getUserData,
    logout,
    updateLang
};

function login(data: any, lang: any) {
    console.log('process.env.REACT_APP_API_URL',process.env.REACT_APP_API_URL)
    return fetch(`${process.env.REACT_APP_API_URL}/login/${lang}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
        })
        .then(fetchWrapper.handleResponse)
        .then(accessToken => {
            return accessToken.results.data;
        });
}

function signup(data: any, lang: any) {
    console.log('process.env.REACT_APP_API_URL',process.env.REACT_APP_API_URL)
    return fetch(`${process.env.REACT_APP_API_URL}/users/signup/${lang}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
        })
        .then(fetchWrapper.handleResponse)
        .then(data => {
            return data;
        });
}

function forgotPassword(data: any, lang: any) {
    console.log('process.env.REACT_APP_API_URL',process.env.REACT_APP_API_URL)
    return fetch(`${process.env.REACT_APP_API_URL}/login/forgotpass/${lang}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
        })
        .then(fetchWrapper.handleResponse)
        .then(data => {
            return data;
        });
}

function changePassword(params: any) {
    return fetchWrapper.post(`${process.env.REACT_APP_API_URL}/login/updatepass`, params);
}

function updateProfile(params: any) {
    return fetchWrapper.put(`${process.env.REACT_APP_API_URL}/users`, params);
}

function getUserData(accessToken: string) {
    return fetchWrapper.get(`${process.env.REACT_APP_API_URL}/users/me`);
}

function updateLang(lang: string) {
    return fetchWrapper.put(`${process.env.REACT_APP_API_URL}/users/updatelang/${lang}`);
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');    
}
