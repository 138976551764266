import React, { lazy } from 'react';
import { dashboardPagesMenu, authPagesMenu, restaurantPagesMenu, restaurantDetailsPagesMenu, userProfilePagesMenu, supportMenu } from '../menu';
import Login from '../pages/presentation/auth/Login';

const LANDING = {
	LANDING: lazy(() =>import('../pages/presentation/landing/Landing')),
};

const ADMINMODULES = {
	DASHBOARD: lazy(() => import('../pages/presentation/dashboard-admin/Dashboard')),
	RESTAURANTS: lazy(() => import('../pages/presentation/restaurants/Restaurants')),
};

const RESTAURANTSMODULES = {
	//DASHBOARD: lazy(() => import('../pages/presentation/dashboard-restaurant/Dashboard')),
	RESTAURANTS: lazy(() => import('../pages/presentation/restaurants/Restaurants')),
	//RESTAURANTDETAILS: lazy(() => import('../pages/presentation/restaurant-details/RestaurantDetails')),
	CATEGORIES: lazy(() => import('../pages/presentation/categories/Categories')),	
	SUBCATEGORIES: lazy(() => import('../pages/presentation/sub-categories/SubCategories')),	
	ITEMS: lazy(() => import('../pages/presentation/items/Items')),	
	PRINT: lazy(() => import('../pages/presentation/print-view/PrintView')),	
	ITEMSVIEW: lazy(() => import('../pages/presentation/items-view/ItemsView')),	
	THEMESELECTION: lazy(() => import('../pages/presentation/theme/Theme')),	
	TRANSLATIONCENTER: lazy(() => import('../pages/presentation/translation-center/TranslationCenter')),	
};

const USERMODULES = {
	PROFILE: lazy(() => import('../pages/presentation/profile/UserProfile')),		
}

const SUPPORTMODULES = {
	CONTACTUS: lazy(() => import('../pages/presentation/contactus/ContactUs')),		
	FAQ: lazy(() => import('../pages/presentation/faq/Faq')),		
}

const AUTH = {
	PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
};

const presentation = [
	/**
	 * Landing
	 */
	{
		path: '/landing',
		element: <LANDING.LANDING />,
	},
	{
		path: authPagesMenu.page404.path,
		element: <AUTH.PAGE_404 />,
	},
	{
		path: authPagesMenu.login.path,
		element: <Login />,
	},

	/** ************************************************** */	

	/*{
		path: dashboardPagesMenu.dashboard.path,
		element: <ADMINMODULES.DASHBOARD />,
	},
	{
		path: restaurantPagesMenu.dashboard.path,
		element: <RESTAURANTSMODULES.DASHBOARD />,
	},*/
	{
		path: restaurantPagesMenu.restaurants.path,
		element: <RESTAURANTSMODULES.RESTAURANTS />,
	},
	/*{
		path: restaurantDetailsPagesMenu.restaurantDetails.path,
		element: <RESTAURANTSMODULES.RESTAURANTDETAILS />,
	},*/

	{
		path: restaurantDetailsPagesMenu.menu.subMenu.categories.path,
		element: <RESTAURANTSMODULES.CATEGORIES />,
	},

	{
		path: restaurantDetailsPagesMenu.menu.subMenu.subCategories.path,
		element: <RESTAURANTSMODULES.SUBCATEGORIES />,
	},

	{
		path: restaurantDetailsPagesMenu.menu.subMenu.items.path,
		element: <RESTAURANTSMODULES.ITEMS />,
	},

	{
		path: restaurantDetailsPagesMenu.menu.subMenu.print.path,
		element: <RESTAURANTSMODULES.PRINT />,
	},
	
	{
		path: restaurantDetailsPagesMenu.menu.subMenu.itemsView.path,
		element: <RESTAURANTSMODULES.ITEMSVIEW />,
	},
	{
		path: restaurantDetailsPagesMenu.menu.subMenu.themeTable.path,
		element: <RESTAURANTSMODULES.THEMESELECTION />,
	},
	{
		path: restaurantDetailsPagesMenu.menu.subMenu.translationCenter.path,
		element: <RESTAURANTSMODULES.TRANSLATIONCENTER />,
	},

	{
		path: userProfilePagesMenu.profile.path,
		element: <USERMODULES.PROFILE />,
	},	

	{
		path: supportMenu.contact.path,
		element: <SUPPORTMODULES.CONTACTUS />,
	},
	
	{
		path: supportMenu.faq.path,
		element: <SUPPORTMODULES.FAQ />,
	},
		
];
const contents = [...presentation];

export default contents;
