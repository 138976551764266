import React from 'react';
import Footer from '../../../layout/Footer/Footer';
import Popovers from '../../../components/bootstrap/Popovers';

const DefaultFooter = () => {
	return (
		<></>
		/*<Footer>
			<div className='container-fluid'>
				<div className='row'>
					<div className='col'>
						Copyright © {new Date().getFullYear()} Bitte. All rights reserved
					</div>
					<div className='col-auto'>
						
					</div>
				</div>
			</div>
	</Footer>*/
	);
};

export default DefaultFooter;
