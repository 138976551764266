import { fetchWrapper } from '../helpers';

export const categoriesServices = {
    getCategories,
    getSubCategories,
    deleteCategory,
    deleteSubCategory,
    toggleCategory,
    toggleSubCategory,
    deleteSubCategoryImage
};

function getCategories(restaurantId: number) {
    return fetchWrapper.get(`${process.env.REACT_APP_API_URL}/rests/category/${restaurantId}`);
}

/*function getSubCategories(categoryId: number) {
    return fetchWrapper.get(`${process.env.REACT_APP_API_URL}/rests/subcategory/${categoryId}`);
}*/

function getSubCategories(restaurantId: number) {
    return fetchWrapper.get(`${process.env.REACT_APP_API_URL}/rests/subcat/${restaurantId}`);
}

function deleteCategory(categoryId: number) {
    return fetchWrapper.delete(`${process.env.REACT_APP_API_URL}/rests/category/${categoryId}`);
}

function deleteSubCategory(subCategoryId: number) {
    return fetchWrapper.delete(`${process.env.REACT_APP_API_URL}/rests/subcategory/${subCategoryId}`);
}

function toggleCategory(categoryId: number, value:boolean) {
    return fetchWrapper.put(`${process.env.REACT_APP_API_URL}/rests/toggle/cat/${categoryId}/${value}`);
}

function toggleSubCategory(subCategoryId: number, value:boolean) {
    return fetchWrapper.put(`${process.env.REACT_APP_API_URL}/rests/toggle/subcat/${subCategoryId}/${value}`);
}

function deleteSubCategoryImage(subCategoryId: number) {
    return fetchWrapper.delete(`${process.env.REACT_APP_API_URL}/rests/subcatimage/${subCategoryId}`);
}