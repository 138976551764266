import React, { createContext, FC, ReactNode, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { authenticationService} from '../services';

export interface IAuthContextProps {
	authToken: string;
	setAuthToken?(...args: unknown[]): unknown;
	userData: any;
}
const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps);

interface IAuthContextProviderProps {
	children: ReactNode;
}
export const AuthContextProvider: FC<IAuthContextProviderProps> = ({ children }) => {
	
	const [authToken, setAuthToken] = useState<string>(localStorage.getItem('authToken') || '');
	const [userData, setUserData] = useState<any>({});

	useEffect(() => {
		localStorage.setItem('authToken', authToken);
	}, [authToken]);

	useEffect(() => {
		if (authToken !== '') {
			authenticationService.getUserData(authToken)
			.then((user) => {				
				setUserData(user.results.data);                         
			})
			.catch();
		} else {
			setUserData({});
		}
	}, [authToken]);

	const value = useMemo(
		() => ({
			authToken,
			setAuthToken,
			userData,
		}),
		[authToken, userData],
	);
	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
AuthContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AuthContext;
